@tailwind base;
@tailwind components;
@tailwind utilities;

/* Adds style to the react-confirm-alert background */
.react-confirm-alert-overlay {
  --tw-bg-opacity: 0.7 !important;
  background: rgb(107 114 128 / var(--tw-bg-opacity)) !important;
}

input,
[id^="headlessui-combobox-option"] {
  text-transform: capitalize;
}

/* Adds style to the react-autocomplete */
.autocomplete-wrapper {
  /* width: 350px; */
  width: 100%;
  position: relative;
  display: block;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 0.375rem;
  font-size: 14px;
  width: 100%;
  border-radius: 0.375rem;
  text-transform: capitalize;
}

.autocomplete-wrapper input:focus,
textarea,
input {
  /* border-color: #0F67FF; */
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  position: absolute;
  width: 100%;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #7c3aed;
  border-top: none;
  z-index: 1000;
  text-transform: capitalize;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 0.375rem;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #8b5cf6;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  background-color: #8b5cf6;
  color: #fafbfc;
}

.react-datepicker__calendar-icon {
  width: 1.5em !important;
  height: 1.5em !important;
  padding: 0.5em 0 0 0 !important;
  margin: 0.1em !important;
}

.rdw-editor-wrapper {
  border: 1px solid #e5e7eb;
  border-radius: 6px;
}
.rdw-editor-toolbar {
  border: unset;
  background: unset;
}
.rdw-editor-main {
  border-top: 1px solid #e5e7eb;
  padding: 8px;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

@media screen and (max-width: 640px) {
  main {
    width: 90vw;
  }
  .tabs {
    width: 96vw;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 0 3px 0;
    margin: auto;
  }
}
